<template>
  <div class="Users">
    <!-- <el-row class="mb-20 pd-10" :gutter="20">
      <el-col :span="7">
        <div class="flex-c-nw">
          <span class="mr-20 text-nw">查询条件</span>
          <el-select
            style="width: 100%"
            v-model="queryType"
            clearable
            placeholder="请选择"
            @clear="handleClearSelected"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="7">
        <el-date-picker
          v-if="queryType === '2'"
          v-model="dateVal"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :shortcuts="shortcuts"
          style="width: 100%"
          @change="onDateChange"
        >
        </el-date-picker>
        <el-input
          v-else
          v-model="queryVal"
          placeholder="请输入查询参数"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-col>
    </el-row> -->
    <el-skeleton :loading="listLoading">
      <el-table
        border
        height="500"
        @selection-change="onUserSelectionChange"
        highlight-current-row
        :data="tableData"
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="50"></el-table-column> -->
        <el-table-column prop="userId" label="用户ID"> </el-table-column>
        <el-table-column prop="telephone" label="用户账号"> </el-table-column>
        <el-table-column
          prop="created_time"
          :formatter="dateFormat"
          label="创建时间"
        >
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template #default="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">
              订单详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <p>推广人数：{{ countNum }}</p>
    </el-skeleton>
    <el-dialog
      title="订单详情"
      v-model="dialogFormVisible"
      width="40%"
      @closed="onDialogClosed"
    >
      <el-skeleton :loading="orderLoading">
        <el-table stripe :data="targetOrderData" style="width: 100%">
          <el-table-column prop="id" label="订单ID"> </el-table-column>
          <el-table-column prop="totalPrices" label="实付金额">
          </el-table-column>
          <el-table-column prop="telephone" label="付款状态">
            <template #default="scope">
              {{
                scope.row.status === "1"
                  ? "等待付款"
                  : scope.row.status === "2"
                  ? "完成付款"
                  : "关闭"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间">
          </el-table-column>
          <el-table-column prop="updatedTime" label="更新时间">
          </el-table-column>
        </el-table>
      </el-skeleton>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">
            确 定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { ElMessage } from "element-plus";
// const BASE_URL =
//   process.env.NODE_ENV === "production" ? "http://118.190.39.21:8082" : "/api";
// const BASE_URL81 =
//   process.env.NODE_ENV === "production"
//     ? "http://118.190.39.21:8081"
//     : "/81api";
const BASE_URL =
  process.env.NODE_ENV === "production" ? "http://119.45.30.192:20081" : "/api";
const BASE_URL81 =
  process.env.NODE_ENV === "production"
    ? "http://119.45.30.192:20080"
    : "/81api";
export default {
  name: "Users",
  data() {
    return {
      options: [
        {
          value: "1",
          label: "账号",
        },
        {
          value: "2",
          label: "到期日期",
        },
      ],
      tableData: [],
      countNum: "",
      targetOrderData: [],
      dialogFormVisible: false,
      queryVal: "",
      queryType: "",
      userSelection: [],
      record: undefined,
      listLoading: false,
      orderLoading: false,
      shortcuts: [
        {
          text: "最近一周",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })(),
        },
        {
          text: "最近一个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })(),
        },
        {
          text: "最近三个月",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })(),
        },
      ],
      dateVal: "",
      dateStart: moment(new Date().setDate(new Date().getDate() - 1)).format(
        "YYYYMMDD"
      ),
      dateEnd: moment(new Date().setDate(new Date().getDate())).format(
        "YYYYMMDD"
      ),
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    handleQuery() {
      let _this = this;
      if (_this.queryType === "2") {
        _this.getTableData(_this.queryVal);
      } else {
        axios
          .request(BASE_URL + `/ego/vpn-sso/optUser/${_this.queryVal}`)
          .then((res) => {
            _this.tableData = [res.data.data];
          })
          .catch(() => {
            _this.tableData = [];
          });
      }
    },
    handleClearSelected() {
      this.queryVal = undefined;
    },
    handleClick(record) {
      const ID = sessionStorage.getItem("token");
      this.orderLoading = true;
      axios
        .request({
          url: BASE_URL + `/ego/vpn-bss/bills/agent/userId/${record.userId}`,
          headers: {
            Authorization: "bearer " + ID,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.targetOrderData = res.data.data;
          } else {
            ElMessage.error("获取数据失败！请联系管理员！");
          }
        })
        .finally(() => {
          this.orderLoading = false;
        });
      this.dialogFormVisible = true;
    },
    onUserSelectionChange(val) {
      this.userSelection = val;
    },
    onDateChange(date) {
      this.dateStart = moment(date[0]).format("YYYYMMDD");
      this.dateEnd = moment(date[1]).format("YYYYMMDD");
    },
    getTableData() {
      let _this = this;
      _this.listLoading = true;
      const ID = sessionStorage.getItem("token");
      axios
        .request({
          url: BASE_URL81 + `/ego/vpn-sso/agent/listUserInfo`,
          headers: {
            Authorization: "bearer " + ID,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            _this.tableData = res.data.data.userInfoList;
            _this.countNum = res.data.data.countNum;
          } else {
            ElMessage.error("获取数据失败！请联系管理员！");
          }
        })
        .finally(() => {
          _this.listLoading = false;
        });
    },
    onDialogClosed() {
      this.targetOrderData = [];
    },
    dateFormat(row) {
      let date = row.createdTime;
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>
<style lang="scss" scoped>
.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.pd-10 {
  padding: 10px;
}

.flex-c-nw {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.text-nw {
  white-space: nowrap;
}
</style>
